import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import ActualTimeline from '../components/ActualTimeline'

class Timeline extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="Timeline" />
        <HeaderGeneric
          title="Timeline"
          subtitle="Here is a timeline of my journey so far! It includes both personal and work-related projects."
        />
        <div /*id="main"*/>
          <section>
            <ActualTimeline/>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Timeline
