import React from 'react'
import { SvgIcon } from '@material-ui/core'

const AppveyorIcon = props => (
<SvgIcon width="256px" height="256px" viewBox="0 0 256 256" version="1.1" xmlns="http://www.w3.org/2000/SvgIcon" ignore_this_xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid">
    <g fill-rule="evenodd">
        <path d="M127.585761,0 C198.834951,0 256,57.1650485 256,127.585761 C256,198.834951 198.834951,256 127.585761,256 C57.1650485,256 0,198.834951 0,127.585761 C0,57.1650485 57.1650485,0 127.585761,0 L127.585761,0 Z M159.067961,152.440129 C144.983819,170.666667 119.300971,173.980583 101.902913,159.89644 C84.5048544,146.640777 82.0194175,120.957929 96.1035599,102.731392 C111.016181,85.3333333 136.699029,82.0194175 154.097087,95.2750809 C171.495146,109.359223 173.980583,135.042071 159.067961,152.440129 L159.067961,152.440129 Z M91.1326861,236.116505 L140.012945,188.893204 C121.786408,193.035599 102.731392,189.721683 87.8187702,177.294498 C70.420712,164.038835 62.9644013,141.669903 65.4498382,120.957929 L21.5404531,169.009709 C21.5404531,169.009709 15.7411003,158.239482 14.0841424,138.355987 L91.1326861,77.8770227 C114.330097,61.3074434 145.812298,60.4789644 168.18123,77.8770227 C193.035599,97.7605178 198.006472,134.213592 179.779935,161.553398 L123.443366,241.915858 C115.158576,241.915858 98.5889968,239.430421 91.1326861,236.116505 L91.1326861,236.116505 Z" fill="#00B3E0"></path>
    </g>
</SvgIcon>

)

export default AppveyorIcon
