import React from 'react'
import { SvgIcon } from '@material-ui/core'

const SpaCyIconColorless = props => (
<SvgIcon version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/SvgIcon" ignore_this_xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 308.5 595.3 213" enable-background="new 0 308.5 595.3 213">
<path _fill="#09a3d5" d="M73.7,395.2c-13.5-1.6-14.5-19.7-31.8-18.1c-8.4,0-16.2,3.5-16.2,11.2c0,11.6,17.9,12.7,28.7,15.6
	c18.4,5.6,36.2,9.4,36.2,29.4c0,25.4-19.9,34.2-46.2,34.2c-22,0-44.3-7.8-44.3-28c0-5.6,5.4-10,10.6-10c6.6,0,8.9,2.8,11.2,7.4
	c5.1,9,10.8,13.8,25,13.8c9,0,18.2-3.4,18.2-11.2c0-11.1-11.3-13.5-23-16.2c-20.7-5.8-38.5-8.8-40.6-31.8
	c-2.2-39.2,79.5-40.7,84.2-6.3C85.6,391.4,79.8,395.2,73.7,395.2z M170.9,360.8c28.7,0,45,24,45,53.6c0,29.7-15.8,53.6-45,53.6
	c-16.2,0-26.3-6.9-33.6-17.5v39.2c0,11.8-3.8,17.5-12.4,17.5c-10.5,0-12.4-6.7-12.4-17.5v-114c0-9.3,3.9-15,12.4-15
	c8,0,12.4,6.3,12.4,15v3.2C145.4,368.7,154.7,360.8,170.9,360.8z M164.1,447.6c16.8,0,24.3-15.5,24.3-33.6
	c0-17.7-7.6-33.6-24.3-33.6c-17.5,0-25.6,14.4-25.6,33.6C138.5,432.7,146.7,447.6,164.1,447.6z M235.4,388.8c0-20.6,23.7-28,46.7-28
	c32.3,0,45.6,9.4,45.6,40.6v30c0,7.1,4.4,21.3,4.4,25.6c0,6.5-6,10.6-12.4,10.6c-7.1,0-12.4-8.4-16.2-14.4
	c-10.5,8.4-21.6,14.4-38.6,14.4c-18.8,0-33.6-11.1-33.6-29.4c0-16.2,11.6-25.5,25.6-28.7c0,0.1,45-10.6,45-10.7
	c0-13.8-4.9-19.9-19.4-19.9c-12.8,0-19.3,3.5-24.3,11.2c-4,5.8-3.5,9.3-11.2,9.3C240.8,399.3,235.4,395.1,235.4,388.8z M273.8,450.7
	c19.7,0,28-10.4,28-31.1v-4.4c-5.3,1.8-26.7,7.1-32.5,8c-6.2,1.2-12.4,5.8-12.4,13.1C257.1,444.3,265.3,450.7,273.8,450.7z
	 M418.5,321.7c27.8,0,57.9,16.6,57.9,43c0,6.8-5.1,12.4-11.8,12.4c-9.1,0-10.4-4.9-14.4-11.8c-6.7-12.3-14.6-20.5-31.8-20.5
	c-26.6-0.2-38.5,22.6-38.5,51c0,28.6,9.9,49.2,37.4,49.2c18.3,0,28.4-10.6,33.6-24.3c2.1-6.3,5.9-12.4,13.8-12.4
	c6.2,0,12.4,6.3,12.4,13.1c0,28-28.6,47.4-58,47.4c-32.2,0-50.4-13.6-60.4-36.2c-4.9-10.8-8-22-8-37.4
	C350.5,351.8,375.8,321.7,418.5,321.7L418.5,321.7z M577.5,360.8c7.1,0,11.2,4.6,11.2,11.8c0,2.9-2.3,8.7-3.2,11.8l-34.2,89.9
	c-7.6,19.5-13.3,33-39.2,33c-12.3,0-23-1.1-23-11.8c0-6.2,4.7-9.3,11.2-9.3c1.2,0,3.2,0.6,4.4,0.6c1.9,0,3.2,0.6,4.4,0.6
	c13,0,14.8-13.3,19.4-22.5l-33-81.7c-1.9-4.4-3.2-7.4-3.2-10c0-7.2,5.6-12.4,13.1-12.4c8.4,0,11.7,6.6,13.8,13.8l21.8,64.8
	l21.8-59.9C566.1,370.2,566.4,360.8,577.5,360.8z"/>
</SvgIcon>

)

export default SpaCyIconColorless
