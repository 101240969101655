import React from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { GitHub, School, Star, Web } from '@material-ui/icons'
import * as svg_icons from '../svg_icon_components/index.js'


const ActualTimeline = (props) => (
    
    <VerticalTimeline layout='1-column'>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={elementContentStyle}
        contentArrowStyle={elementContentArrowStyle}
        date="May 2020 - August 2020"
        iconStyle={{ background: 'rgb(9, 163, 213)', color: '#fff' }}
        icon={ <svg_icons.SpaCyIconColorless /> }
      >
        <TimelineElementTitle>
          MyCareersFuture
          <TimelineElementTitleIconDiv>
            <svg_icons.SpaCyIcon/>
            <svg_icons.DockerIcon/>
            <svg_icons.GitlabIcon/>
            <svg_icons.PythonIcon/>
          </TimelineElementTitleIconDiv>
        </TimelineElementTitle>
        <TimelineElementSubtitle>
          Software Engineering and Machine Learning Intern
        </TimelineElementSubtitle>
        <TimelineElementContent>
          Made various improvements to a natural-language resume parser. Besides increasing the
          parser's recall twofold, I also oversaw the manual annotation of the dataset, and added a
          data pre-processing pipeline to ensure that the model can be easily trained in future. 
        </TimelineElementContent>
        <TimelineElementButton to="https://mycareersfuture.sg">
          <Web />
        </TimelineElementButton>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        contentStyle={elementContentStyle}
        contentArrowStyle={elementContentArrowStyle}
        date="May 2020"
        iconStyle={{ background: 'rgb(255, 199, 11)', color: '#fff' }}
        icon={<Star />}
      >
        <TimelineElementTitle>
          NUS School of Computing Dean's List
        </TimelineElementTitle>
        <TimelineElementSubtitle>
          Academic Year 2019/2020 Semester 2
        </TimelineElementSubtitle>
        <TimelineElementButton to="https://www.comp.nus.edu.sg/programmes/ug/honour/deans/">
          <Web />
        </TimelineElementButton>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={elementContentStyle}
        contentArrowStyle={elementContentArrowStyle}
        date="February 2020 - May 2020"
        iconStyle={elementContentStyle}
        icon={<svg_icons.PythonIcon />}
      >
        <TimelineElementTitle>
          CDCL SAT Solver
          <TimelineElementTitleIconDiv>
            <svg_icons.PythonIcon/>
          </TimelineElementTitleIconDiv>
        </TimelineElementTitle>
        <TimelineElementContent>
          Working in a team of two, I conceptualized and implemented a solver for the 
          Boolean Satisfiability Problem (SAT) based off the CDCL algorithm. The solver 
          incorporates a number of novel heuristics, such as 1-UIP and learned clause 
          forgetting, in order to optimize the overall efficiency.
        </TimelineElementContent>
        <TimelineElementButton to="https://github.com/leowwb/cdcl-sat/">
          <GitHub />
        </TimelineElementButton>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={elementContentStyle}
        contentArrowStyle={elementContentArrowStyle}
        date="February 2020 - May 2020"
        iconStyle={{ background: '#EE4C2C', color: '#fff' }}
        icon={<svg_icons.PytorchIconColorless />}
      >
        <TimelineElementTitle>
          Neural Networks in Automated Theorem Proving
          <TimelineElementTitleIconDiv>
            <svg_icons.PytorchIcon/>
            <svg_icons.TensorflowIcon/>
            <svg_icons.PythonIcon/>
          </TimelineElementTitleIconDiv>
        </TimelineElementTitle>
        <TimelineElementContent>
          As part of a team of six, I studied and analyzed the potential usefulness of RNNs
          (recurrent neural networks) and LSTMs (long short-term memory networks) in the
          field of automated theorem proving. 
        </TimelineElementContent>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        contentStyle={elementContentStyle}
        contentArrowStyle={elementContentArrowStyle}
        date="December 2019"
        iconStyle={{ background: 'rgb(255, 199, 11)', color: '#fff' }}
        icon={<Star />}
      >
        <TimelineElementTitle>
          NUS School of Computing Dean's List
        </TimelineElementTitle>
        <TimelineElementSubtitle>
          Academic Year 2019/2020 Semester 1
        </TimelineElementSubtitle>
        <TimelineElementButton to="https://www.comp.nus.edu.sg/programmes/ug/honour/deans/">
          <Web />
        </TimelineElementButton>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={elementContentStyle}
        contentArrowStyle={elementContentArrowStyle}
        date="August 2019 - November 2019"
        iconStyle={{ background: 'rgb(234, 45, 47)', color: '#fff' }}
        icon={<svg_icons.JavaIconColorless />}
      >
        <TimelineElementTitle>
          KeyboardFlashCards
          <TimelineElementTitleIconDiv>
            <svg_icons.JavaIcon/>
            <svg_icons.AppveyorIcon/>
            <svg_icons.JenkinsIcon/>
            <svg_icons.NetlifyIcon/>
          </TimelineElementTitleIconDiv>
        </TimelineElementTitle>
        <TimelineElementSubtitle>
          Team and Integration Lead; Backend Developer
        </TimelineElementSubtitle>
        <TimelineElementContent>
          I led a team of five students in designing and creating a desktop 
          flashcard application in Java. Besides overseeing the overall 
          integration of the project, I incorporated an external library into the 
          application that allowed the user to read from and write to document 
          files, hence improving flexibility.
        </TimelineElementContent>
        <TimelineElementButton to="https://ay1920s1-cs2103t-t12-4.github.io/main/index.html">
          <Web />
        </TimelineElementButton>
        <TimelineElementButton to="https://github.com/AY1920S1-CS2103T-T12-4/main">
          <GitHub />
        </TimelineElementButton>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        contentStyle={elementContentStyle}
        contentArrowStyle={elementContentArrowStyle}
        date="August 2019"
        iconStyle={{ background: 'rgb(255, 199, 11)', color: '#fff' }}
        icon={<Star />}
      >
        <TimelineElementTitle>
          Smart Nation Scholarship
        </TimelineElementTitle>
        <TimelineElementSubtitle>
          Government Technology Agency of Singapore
        </TimelineElementSubtitle>
        <TimelineElementButton to="https://www.smartnation.gov.sg/">
          <Web />
        </TimelineElementButton>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={elementContentStyle}
        contentArrowStyle={elementContentArrowStyle}
        date="May 2019 - August 2019"
        iconStyle={{ background: '#A62C46', color: '#ffffff' }}
        icon={<svg_icons.RailsIconColorless />}
      >
        <TimelineElementTitle>
          Computing for Voluntary Welfare Organisations (CVWO)
          <TimelineElementTitleIconDiv>
            <svg_icons.RailsIcon/>
            <svg_icons.RubyIcon/>
            <svg_icons.PostgresqlIcon/>
            <svg_icons.CapistranoIcon/>
          </TimelineElementTitleIconDiv>
        </TimelineElementTitle>
        <TimelineElementSubtitle>
          Software Engineer and Backend Developer Intern
        </TimelineElementSubtitle>
        <TimelineElementContent>
          Attached to Lions Befrienders, I worked in a team to conceptualize and
          implement a new module for the organization's management system. My
          contribution was the backend implementation. Created using Ruby on
          Rails, it allows staff to easily keep track of senior citizens in need
          of special attention.
        </TimelineElementContent>
        <TimelineElementButton to="https://www.comp.nus.edu.sg/~vwo/">
          <Web />
        </TimelineElementButton>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={elementContentStyle}
        contentArrowStyle={elementContentArrowStyle}
        date="January 2019 - May 2019"
        iconStyle={{ background: '#00D8FF', color: '#fff' }}
        icon={<svg_icons.ReactIconColorless />}
      >
        <TimelineElementTitle>
          Source Academy
          <TimelineElementTitleIconDiv>
            <svg_icons.ReactIcon/>
            <svg_icons.ReduxIcon/>
            <svg_icons.NodejsIcon/>
          </TimelineElementTitleIconDiv>
        </TimelineElementTitle>
        <TimelineElementSubtitle>
          Frontend Developer
        </TimelineElementSubtitle>
        <TimelineElementContent>
          I made a massive overhaul to the existing error message system and
          implemented a substitution model visualizer. Together, these changes bring a
          better understanding of JavaScript programs to over 600 freshmen per semester.
        </TimelineElementContent>
        <TimelineElementButton to="https://sourceacademy.nus.edu.sg/">
          <Web/>
        </TimelineElementButton>
        <TimelineElementButton to="https://github.com/source-academy/cadet-frontend">
          <GitHub/>2
        </TimelineElementButton>
        <TimelineElementButton to="https://github.com/source-academy/js-slang">
          <GitHub/>1
        </TimelineElementButton>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        contentStyle={elementContentStyle}
        contentArrowStyle={elementContentArrowStyle}
        date="August 2018 - present"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<School/>}
      >
        <TimelineElementTitle>
          National University of Singapore, School of Computing
        </TimelineElementTitle>
        <TimelineElementSubtitle>
          Bachelor of Computing in Computer Science
        </TimelineElementSubtitle>
        {/*<TimelineElementContent>
          nus here? oh yes fch etc etc
        </TimelineElementContent>*/}
        <TimelineElementButton to="https://www.comp.nus.edu.sg/">
          <Web/>
        </TimelineElementButton>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={elementContentStyle}
        contentArrowStyle={elementContentArrowStyle}
        date="March 2018 - April 2018"
        iconStyle={{ background: '#222C37', color: '#fff' }}
        icon={<svg_icons.UnityIconColorless/>}
      >
        <TimelineElementTitle>
          Mancala
          <TimelineElementTitleIconDiv>
            <svg_icons.UnityIcon/>
            <svg_icons.CSharpIcon/>
          </TimelineElementTitleIconDiv>
        </TimelineElementTitle>
        <TimelineElementContent>
          I designed and implemented, from scratch, an artificial intelligence system to 
          play the game “Mancala” (a board game similar to the traditional Malay
          “congkak”). This was in the form of an Android application, complete with a 3D
          interface.
        </TimelineElementContent>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        contentStyle={elementContentStyle}
        contentArrowStyle={elementContentArrowStyle}
        date="January 2010 - December 2015"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={ <School/> }
      >
        <TimelineElementTitle>
          NUS High School of Mathematics and Science
        </TimelineElementTitle>
        <TimelineElementSubtitle>
          Diploma (High Distinction) with Majors in Mathematics, Chemistry, Physics and Computing Studies
        </TimelineElementSubtitle>
        <TimelineElementButton to="https://www.nushigh.edu.sg/">
          <Web />
        </TimelineElementButton>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        icon={<Star />}
      />
    </VerticalTimeline>
)

export default ActualTimeline

const TimelineElementTitle = (props) => (
    <h3 className="vertical-timeline-element-title"
    style={{color:'#000000', fontSize:'25px'}}
    >
      {props.children}
    </h3>
  )
  
  const TimelineElementSubtitle = (props) => (
    <h4 className="vertical-timeline-element-subtitle"
    style={{color:'#000000'}}
    >
      {props.children}
    </h4>
  )
  
  const TimelineElementContent = (props) => (
    <p style={{fontWeight:'300', color:'#000000'}}>
      {props.children}
    </p>
  )
  
  const TimelineElementTitleIconDiv = (props) => (
    <div style={{float:'right'}}>
      {React.Children.map(
        props.children, x => (
        <>{x}&nbsp;</>
      ))}
    </div>
  )
  
  const TimelineElementButton = (props) => (
    <a href={props.to} style={{
      transition:"background-color 0.2s ease-in-out, color 0.2s ease-in-out",
      borderRadius:"8px",     // curvature of the rounded border
      border:"0",
      cursor:"pointer",
      display:"inline-block",
      float:"right",          // align right
      fontWeight:"300",
      height:"2.75em",
      lineHeight:"2.75em",
      marginLeft:"3px",       // space between buttons
      minWidth:"0.25em",      // lower bound on how thin it can get
      padding:"0 0.75em",      // space between contents and border
      textAlign:"center",
      textDecoration:"none",
      whiteSpace:"nowrap",
      boxShadow:"inset 0 0 0 1px #dddddd",
      position:"relative",
      color:"#636363"}}>
        <div style={{top:"14%", position:"relative"}}>
          {props.children}
        </div>
    </a>
  )
  
  const elementContentStyle = { background: 'rgb(255, 255, 255)', color: '#000' }
  const elementContentArrowStyle = { borderRight: '7px solid  rgb(255, 255, 255)' }